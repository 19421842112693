<style lang="scss">
.tiponcard {
  .tabelle-box {
    .head-row {
      [class*='col-'] {
        text-transform: none !important;
      }
    }
    .col-12 {
      @media (max-width: 992px) {
        text-align: center !important;
      }
    }
  }
  .single-row {
    margin-bottom: 20px;

    img {
      max-height: 300px;
      display: block;
      margin: 20px auto;
    }
  }
  h3 {
    margin-top: 0;
  }
  .tab-group-container {
    margin: 50px 0;
    .title-container {
      .title {
        &:hover {
          background: #eee;
        }
      }
      .active {
      }
    }
  }
}
</style>

<template>
  <div class="tiponcard content container">
    <h1>Ihre Tip-on-Card auf der Kleinen Zeitung</h1>
    <h2>Der Hingucker.</h2>
    <ul class="content-ul">
      <li><strong>Top platziert.</strong> Ihre Werbebotschaft auf der Titelseite oder der U4 fällt auf.</li>
      <li><strong>Werbung zum Angreifen.</strong> Einfach abziehen und mitnehmen, z.B. als Gutschein.</li>
      <li><strong>Star des Tages.</strong> An Ihrem gewünschten Erscheinungstag gibt es diese Werbeform nur einmal.</li>
    </ul>

    <TabGroup>
      <Tab tabid="toc-tab1" :title="'Tip-on-Card'" active>
        <div class="row inner-content">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/beilagensonderwerbeformen/tip-on-card-normal.jpg" alt="Tip-on-Card" class="img-fluid img-s-75 shadow img-center" />
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="single-row">
              <h2>Tip-on-Card</h2>
              <p><strong>Format</strong><br />105 x 148 mm (+3 mm Beschnitt)</p>
              <p><strong>Papier</strong><br />LWC, 170 g/m² matt mit Volumen</p>
              <p><strong>Platzierung</strong><br />Titelseite bzw. U4</p>
              <p><strong>Verarbeitung</strong><br />Tip-on-Cards werden maschinell ausschließlich in Hochformat verarbeitet.</p>
              <p><strong>Mindestmengen:</strong></p>
              <ul class="content-ul">
                <li>Titelseite: 50.000 Stück (im jeweiligen Bundesland; Addition nicht möglich)</li>
                <li>U4: 5.000 Stück</li>
              </ul>
            </div>
          </div>
        </div>
      </Tab>
      <Tab tabid="toc-tab2" :title="'Tip-on-Card perforiert'">
        <div class="row inner-content">
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="bild-box">
              <img src="@/assets/img/img-content/beilagensonderwerbeformen/tip-on-card-perforiert.jpg" alt="Tip-on-Card" class="img-fluid img-s-75 shadow img-center" />
            </div>
          </div>
          <div class="col-lg-6 justify-content-center align-self-center">
            <div class="single-row">
              <h2>Tip-on-Card perforiert</h2>
              <p><strong>Format</strong><br />105 x 148 mm (+3 mm Beschnitt)</p>
              <p><strong>Papier</strong><br />BD, 200g/m² matt od. glänzend</p>
              <p><strong>Platzierung</strong><br />Titelseite bzw. U4</p>
              <p><strong>Verarbeitung</strong><br />Tip-on-Cards werden maschinell ausschließlich in Hochformat verarbeitet.</p>
              <p><strong>Mindestmengen:</strong></p>
              <ul class="content-ul">
                <li>Titelseite: mindestens 50.000 Stück (im jeweiligen Bundesland, Addition nicht möglich)</li>
                <li>U4: mindestens 5.000 Stück</li>
              </ul>
            </div>
          </div>
        </div>
        <div class="tabelle-box tabelle-width-50">
          <div class="headline">
            Perforationskosten
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-6 text-align-left">Pauschalkosten</div>
            <div class="col-lg-6 text-align-right">Tarif</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Pauschalkosten</div>
            <div class="col-lg-6 col-6 text-align-left text-right-small">1-fach Perforation</div>
            <div class="col-6 text-align-left visible-small">Tarif</div>
            <div class="col-lg-6 col-6 text-align-right">400,00</div>
          </div>
          <div class="row content-row">
            <div class="col-6 text-align-left visible-small">Pauschalkosten</div>
            <div class="col-lg-6 col-6 text-align-left text-right-small">2-fach Perforation</div>
            <div class="col-6 text-align-left visible-small">Tarif</div>
            <div class="col-lg-6 col-6 text-align-right">500,00</div>
          </div>
        </div>
      </Tab>
    </TabGroup>

    <div class="row">
      <div class="col-lg-12">
        <h2>Geringer Preis, maximale Wirkung</h2>
        <p>Die Kosten für die Tip-on-Card setzen sich aus den Druck- und Verarbeitungskosten und Perforationskosten (optional) zusammen.</p>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Verarbeitungskosten
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left">unter 80.000 Stück</div>
            <div class="col-lg-4 text-align-right">MO - FR</div>
            <div class="col-lg-4 text-align-right">SA - SO</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">Titelseite</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">166,32</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">173,46</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">U4</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">130,68</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">136,29</div>
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="tabelle-box">
          <div class="headline">
            Verarbeitungskosten
          </div>
          <div class="row head-row hide-small">
            <div class="col-lg-4 text-align-left">über 80.000 Stück</div>
            <div class="col-lg-4 text-align-right">MO - FR</div>
            <div class="col-lg-4 text-align-right">SA - SO</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">Titelseite</div>
            <div class="col-6 text-align-left visible-small">MO - FR</div>
            <div class="col-lg-4 col-6 text-align-right">163,80</div>
            <div class="col-6 text-align-left visible-small">SA - SO</div>
            <div class="col-lg-4 col-6 text-align-right">170,94</div>
          </div>
          <div class="row content-row">
            <div class="col-lg-4 col-12 text-align-left">U4</div>
            <div class="col-6 text-align-left visible-small">Mo-Sa</div>
            <div class="col-lg-4 col-6 text-align-right">128,70</div>
            <div class="col-6 text-align-left visible-small">So</div>
            <div class="col-lg-4 col-6 text-align-right">134,31</div>
          </div>
        </div>
      </div>
    </div>

    <div class="tabelle-box tabelle-width-50">
      <div class="headline">
        Druckkosten
      </div>
      <div class="row head-row hide-small">
        <div class="col-lg-4 text-align-left">Stück</div>
        <div class="col-lg-4 text-align-right">Preis</div>
        <div class="col-lg-4 text-align-right">+1.000 Stk.</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">5.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">255,00</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">15,90</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">10.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">330,00</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">15,90</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">20.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">487,50</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">15,90</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">30.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">630,00</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">15,90</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">40.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">732,25</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">15,37</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">50.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">875,00</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">14,70</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">100.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">1.417,50</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">13,37</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">200.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">2.405,00</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">12,61</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">300.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">3.112,50</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">12,00</div>
      </div>
      <div class="row content-row">
        <div class="col-6 text-align-left visible-small">Stück</div>
        <div class="col-lg-4 col-6 text-align-left text-right-small">400.000</div>
        <div class="col-6 text-align-left visible-small">Preis</div>
        <div class="col-lg-4 col-6 text-align-right">3.900,00</div>
        <div class="col-4 text-align-left visible-small">weitere 1.000 Stück</div>
        <div class="col-lg-4 col-8 text-align-right">11,46</div>
      </div>
    </div>
    <div class="info-txt-preis">Alle Preise verstehen sich per 1000 Stück in Euro, exklusive 5% Werbeabgabe und 20% Umsatzsteuer.</div>
    <div class="btn-std btn-bg-blue-dark color-fff text-center margin-tb-s"><router-link :to="{ name: 'Spezifikationen' }">Informationen zu den Spezifikationen </router-link></div>
    <div class="row justify-content-center align-self-center bg-blue-light highlight-box margin-tb-m">
      <div class="col-sm-6 d-flex align-self-center">
        <p>Mit dem Druckkosten-Rechner können Sie den Preis Ihrer Tip-on-Card sofort berechnen.</p>
      </div>
      <div class="col-sm-6 d-flex align-self-center justify-content-end">
        <div class="btn-std btn-bg-blue-dark">
          <router-link :to="{ name: 'calculator' }">Druckkosten-Rechner</router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
//import Modal from '@/components/Modal.vue';
import TabGroup from '@/components/util/TabGroup.vue';
import Tab from '@/components/util/Tab.vue';

export default defineComponent({
  components: {
    //Modal,
    TabGroup,
    Tab,
  },
});
</script>
